<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#staff"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">{{year}}年{{month}}月{{dateNum}}日({{dow}})出勤状況</h1>
      </div>
    </div>
  </header>

  <main class="p-3">    
    <ul
      class="staff-list grid mb-5"
    >
      <li @click="addStaff">
        <div class="panel">
          <div class="front addstaff">
            <div>
              <div>＋</div>
            </div>
          </div>
        </div>
      </li>
      <StaffCard
        v-for="staff in staffList"
        :key="staff.id"
        :staff='staff'
      />
    </ul>

    <AddStaffModal
      :modal="modal"
      @hidden="resetModal"
    />

    <AttendanceLogList />
  </main>

</template>

<script>
import StaffCard from '@/components/Staff/StaffCard'
import AddStaffModal from '@/components/AddStaff'
import AttendanceLogList from '@/components/Staff/AttendanceLogList'

export default {
  components: {
    StaffCard,
    AddStaffModal,
    AttendanceLogList
  },
  data() {
    return {
      staffList: [],
      date: null,
      dateNum: null,
      modal: false,
    }
  },
  created() {
    this.theDate()
  },
  mounted() {
    this.$store.dispatch('Staff/loadStaffList')
    this.$store.watch(
      (state, getters) => getters['Staff/staffList'],
      (newValue) => {
         this.staffList = newValue
      }
    )
  },
  methods: {
    theDate() {
      this.date = new Date()
      this.dateNum = this.date.getDate()
    },
    addStaff() {
      console.log("ADD STAFF!")
      this.modal = true
    },
    resetModal() {
      this.modal = false
    },
  },
  computed: {
    year() {
      return this.date.getFullYear()
    },
    month() {
      return this.date.getMonth() + 1
    },
    dow() {
      const dow = this.date.getUTCDay()
      const array = ['日','月','火','水','木','金','土']
      return array[dow] 
    },
  },
}
</script>

<style scoped>
.text-white-50 { color: rgba(255, 255, 255, .5); }
</style>

<style>
.panel {
	margin: 0 auto;
	height: 130px;  
	position: relative;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
}

.panel .front,
.panel .back {
	text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
	
.panel .front {
  background-color: var(--bs-secondary);
  color: #fff;
	height: inherit;
	position: absolute;
	top: 0;
	z-index: 900;
	text-align: center;
	-webkit-transform: rotateX(0deg) rotateY(0deg);
	   -moz-transform: rotateX(0deg) rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	-webkit-transition: all .4s ease-in-out;
	   -moz-transition: all .4s ease-in-out;
		-ms-transition: all .4s ease-in-out;
		 -o-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
}

.panel .back {
  background-color: var(--bs-yellow);
	height: inherit;
	position: absolute;
	top: 0;
	z-index: 1000;
	-webkit-transform: rotateY(-180deg);
	   -moz-transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	-webkit-transition: all .4s ease-in-out;
	   -moz-transition: all .4s ease-in-out;
		-ms-transition: all .4s ease-in-out;
		 -o-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
}

.panel .front.addstaff {
  background-color: var(--bs-gray-500);
  color: #000;
  font-weight: bold;
}
.panel.flip .front {
	z-index: 900;
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}
.panel.flip .back {
	z-index: 1000;
	-webkit-transform: rotateX(0deg) rotateY(0deg);
	-moz-transform: rotateX(0deg) rotateY(0deg);
}
</style>